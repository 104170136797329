import React from "react";
import { navigateTo } from "gatsby-link";
import styles from "./newsletterSignup.module.css";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class NewsletterSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <div>
        <div className={styles.bg}>
          <div className="grid grid-justifyCenter">
            <div className="grid-cell u-med-3of4">
              <h5>
                Join our monthly newsletter for personal and business insurance guides.
              </h5>              
              <form 
                name="Lewis Mohr Newsletter Sign Up"
                method="post"
                action="/subscribe-thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <div className="grid grid-full med-grid-fit grid-gutters">
                    <input type="hidden" name="form-name" value="file-upload" />
                    <p hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </p>
                    <div className="grid-cell">
                      <input
                        className={styles.input}
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        onChange={this.handleChange}
                        aria-label="First Name"
                      />
                    </div>
                    <div className="grid-cell">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={this.handleChange}
                        aria-label="Last Name"
                      />
                    </div>
                    <div className="grid-cell">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={this.handleChange}
                        aria-label="Email"
                      />
                    </div>
                    <div className={styles.button}>
                      <button type="submit">Subscribe</button>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewsletterSignup;