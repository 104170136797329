import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { navigateTo } from "gatsby-link";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class GetInsurance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Get Insurance Coverage - Baton Rouge | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "To inquire about getting insurance for yourself or your business, please fill out the form to get in touch with one of our independent insurance agents in Baton Rouge, Louisiana. If you need immediate assistance please give us a call.",
            },
          ]}
        />
        <div className="grid">
          <div className="grid-cell u-full u-large-1of2">
            <div className="side-section">
              <h1>Get an Insurance Quote</h1>
              <p>
                Looking for insurance coverage? Fill out the form below and one
                of our Baton Rouge, Louisiana based insurance agents will be in
                touch within 1 business day. If you need immediate assistance,
                please give us a call at:{" "}
                <a className="no-break" href="tel:2252931086">
                  225-293-1086
                </a>
                .
              </p>
              <form
                name="Lewis Mohr Get Insurance"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <div className="grid grid-gutters-sides grid-full med-grid-1of2">
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="file-upload" />
                  <p hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </p>
                  <div className="grid-cell">
                    <label>
                      First Name
                      <sup>*</sup>
                      <input
                        type="text"
                        id="firstNameField"
                        name="firstName"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Last Name
                      <sup>*</sup>
                      <input
                        type="text"
                        id="lastNameField"
                        name="lastName"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Email
                      <sup>*</sup>
                      <input
                        type="email"
                        id="emailField"
                        name="email"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Phone
                      <sup>*</sup>
                      <input
                        type="phone"
                        id="phoneField"
                        name="phone"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Company Name (Optional)
                      <input
                        type="text"
                        id="companyName"
                        name="Company Name"
                        placeholder=""
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Zip Code
                      <sup>*</sup>
                      <input
                        type="text"
                        id="zipCode"
                        name="Zip Code"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell">
                    <label>
                      Type of Insurance
                      <sup>*</sup>
                      <br />
                      <input
                        type="radio"
                        name="insuranceType"
                        value="Personal"
                        onChange={this.handleChange}
                      />
                      <span className="radio-label">Personal</span>
                      <input
                        type="radio"
                        name="insuranceType"
                        value="Business"
                        onChange={this.handleChange}
                      />
                      <span className="radio-label">Business</span>
                    </label>
                  </div>
                  <div className="grid-cell u-full">
                    <label>
                      Tell us what type of insurance you are needing
                      <sup>*</sup>
                      <textarea
                        type="message"
                        id="messageField"
                        name="Type of Insurance Needed"
                        placeholder=""
                        onChange={this.handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="grid-cell u-full">
                    <div className="disclaimer">
                      Note: No coverage may be bound or altered by email or
                      voice mail. You must speak with a representative.
                    </div>
                  </div>
                  <div className="grid-cell u-full">
                    <button type="submit">Submit Form</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="grid-cell u-full u-large-1of2 u-textCenter">
            <div className="get-insurance-right-section">
              <div className="masterVideoWrapper">
                <div className="videoWrapper">
                  <iframe
                    className="iframe why-us-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/6E1tvivLUPc?rel=0"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="Homeowners Insurance: What You Need to Know"
                  />
                </div>
              </div>
              <h3>Why Choose Lewis Mohr</h3>
              <hr className="short center"></hr>
              <h6>Family-Owned Since 1968</h6>
              <h6>Local Independent Agency</h6>
              <h6>Trusted Industry-Leading Expertise</h6>
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default GetInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    sideImage: file(relativePath: { eq: "homepage-side-image.jpg" }) {
      ...mainBg
    }
    icon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
